import * as React from "react";
import { Breadcrumb, FormSuccess, Container } from "../../components";

const ContactSuccess = () => {
  return (
    <Container>
      <FormSuccess />
      <Breadcrumb currentPage="Contact" currentSlug="/contact" />
    </Container>
  );
};

export default ContactSuccess;
